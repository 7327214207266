import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';

const TitleAuth = ({ titleText, centering }) => {
  return (
    <h2 className={`title-auth ${centering ? 'title-auth--center' : ''}`}>{titleText}</h2>
  );
};

TitleAuth.propTypes = {
  titleText: PropTypes.string.isRequired,
  centering: PropTypes.string
};

export default TitleAuth;