import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterThunk } from '../../../store/thunks/register';

import { resetErrors } from '../data';

import Field from '../../../common/Fields/Field';
import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import FieldPhone from '../../../common/Fields/FieldPhone';
import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import FieldPassword from '../../../common/Fields/FieldPassword';

const RegisterForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const register = useSelector(registerSelectors);
  const phoneRes = register.phone;
  const errors = register.errors;
  const error = register.error;
  const load = register.load;
  const registered = register.registered;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');
  const [midName, setMidName] = useState('');

  useEffect(() => {
    if(phoneRes) {
      navigate('/registration/confirm');
      resetErrors(dispatch, actions.register);
      resetForm();
    }
  }, [phoneRes]);

  useEffect(() => {
    if(registered) {
      navigate('/registration/wait');
    } 
  }, [registered]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      mid_name: midName,
      phone: `+${phone}`,
      email,
      password,
      password_confirmation: passwordConfirmation,
    };
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterThunk(data));
  };

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setPhone('');
    setPassword('');
    setPasswordConfirmation('');
  };

  return (
    <form className="auth__form form" onSubmit={handleSubmit}>
      <div className='form__row form__field'>
        <div className='form-block'>
          <Field 
            name="first_name" 
            type="text"
            value={firstName} 
            labelText="Ім'я" 
            onChange={(e) => setFirstName(e.target.value.trim())} 
            formClass="required"
            required
          />
          {errors?.first_name && <ErrorField errorText={errors.first_name} />}
        </div>

        <div className='form-block'>
          <Field 
            name="last_name" 
            type="text"
            value={lastName} 
            labelText="Прізвище" 
            onChange={(e) => setLastName(e.target.value.trim())} 
            formClass="required"
            required
          />
          {errors?.last_name && <ErrorField errorText={errors.last_name} />}
        </div>
      </div>

      <>
        <Field 
          name="mid_name" 
          type="text"
          value={midName} 
          labelText="По батькові" 
          onChange={(e) => setMidName(e.target.value.trim())} 
          formClass="form__field required"
          required
        />
        {errors?.mid_name && <ErrorField errorText={errors.mid_name} />}
      </>

      <>
        <FieldPhone 
          name="phone" 
          type="tel" 
          valuePhone={phone} 
          onChange={setPhone} 
          labelText="Номер телефону"
          labelMessage="(з приєднаним Telegram)"
          formClass="form__field required"
          required
        />
        {errors?.phone && <ErrorField errorText={errors.phone} />}
      </>

      <>
        <Field 
          name="email"
          type="text"
          value={email} 
          labelText="Email" 
          onChange={(e) => setEmail(e.target.value.trim())} 
          formClass="form__field"
        />
        {errors?.email && <ErrorField errorText={errors.email} />}
      </>

      <>
        <FieldPassword
          name="password"
          value={password} 
          onChange={(e) => setPassword(e.target.value.trim())} 
          labelText="Пароль"
          formClass="form__field required"
          required
        />
        {errors?.password && <ErrorField errorText={errors.password} />}
      </>

      <>
        <FieldPassword
          name="password_confirmation"
          value={passwordConfirmation} 
          onChange={(e) => setPasswordConfirmation(e.target.value.trim())} 
          labelText="Повторіть пароль"
          formClass="form__field required"
          required
        />
      </>

      {(error && !errors)  && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        <LoadingBtn load={load} btnText='Продовжити' />
        <div className="auth__text">
          Вже маєте профіль? 
          <NavLink to="/login" className="link">Увійти</NavLink>
        </div>
      </div>

    </form>
  );
};

export default RegisterForm;